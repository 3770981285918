import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

//Images
import BrandNewIcon from "../../shared/images/car-card-tags/brand-new.svg";
import ComingSoonIcon from "../../shared/images/car-card-tags/coming-soon.svg";
import InDemandIcon from "../../shared/images/car-card-tags/in-demand.svg";
import FeaturedIcon from "../../shared/images/car-card-tags/featured.svg";
import HotOfferIcon from "../../shared/images/car-card-tags/hot-offer.svg";

//Constants
import { carCardTags } from "../../../constants/app-constants";

const CarCardTagComponent = ({ carCardTag }) => {

    const carCardMapping = {
        [carCardTags["IN DEMAND"]]: {
            style: "styles.inDemand",
            imageIcon: InDemandIcon
        },
        [carCardTags.FEATURED]: {
            style: "styles.featured",
            imageIcon: FeaturedIcon
        },
        [carCardTags["HOT OFFER"]]: {
            style: "styles.hotOffer",
            imageIcon: HotOfferIcon
        },
        [carCardTags.RESERVED]: {
            style: "styles.reserved",
            imageIcon: null
        },
        [carCardTags["BRAND NEW"]]: {
            style: "styles.brandNew",
            imageIcon: BrandNewIcon
        },
        [carCardTags["COMING SOON"]]: {
            style: "styles.comingSoon",
            imageIcon: ComingSoonIcon
        },
        [carCardTags.SOLD]: {
            style: "styles.soldTag",
            imageIcon: null
        }
    };
    return (
        <React.Fragment>
            <div className="reserveCarWrap" styleName={`styles.reserveCarWrap ${carCardMapping[carCardTag]?.style}`}>
                {carCardMapping[carCardTag]?.imageIcon && <img src={carCardMapping[carCardTag]?.imageIcon} />}
                <div>{carCardTag}</div>
            </div>
        </React.Fragment>
    );
};

CarCardTagComponent.propTypes = {
    carCardTag: PropTypes.string
};

export default CarCardTagComponent;
