import { b2cDetailPageEvents } from "../../../tracking/msite/b2c-detail-page-events";
import { b2cHomePageEvents } from "../../../tracking/msite/b2c-home-page-events";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";
import { b2cPersonalizedCarAEvents } from "../../../tracking/msite/b2c-personalized-car-events";
import { carForYouPageEvents } from "../../../tracking/msite/car-for-you-page-events";

export const SOURCE = {
    MY_BOOKINGS: "MY_BOOKINGS",
    LISTING: "LISTING",
    WISHLIST: "WISHLIST",
    WISHLIST_SIMILAR_CARS: "WISHLIST_SIMILAR_CARS",
    SIMILAR_CARS: "SIMILAR_CARS",
    RECENT_CARS: "RECENT_CARS",
    FEATURED_CARS: "FEATURED_CARS",
    TDS: "TDS",
    CAR_DETAIL: "CAR_DETAIL",
    HUB_TEST_DRIVE: "HUB_TEST_DRIVE",
    HAND_PICKED_CARS: "HAND_PICKED_CARS",
    AI_PICKED_CARS: "AI_PICKED_CARS"
};

export const BOOKING_STATE = {
    CANCEL: "CANCEL",
    EXPIRED: "EXPIRED",
    BOOKED: "BOOKED",
    CREATED: "CREATED",
    RETURNED: "RETURNED"
};

export const SOURCE_EVENT_DATA = {
    LISTING: {
        eventName: b2cListingPageEvents.addToWishlistListingPage,
        addEventLabel: "add_to_wishlist/listing page",
        removeEventLabel: "Remove from wishlist - Listings page"
    },
    SIMILAR_CARS: {
        eventName: b2cDetailPageEvents.addToWishlistDetailPage,
        addEventLabel: "Add to wishlist - similar-cars",
        removeEventLabel: "Remove from wishlist - similar-cars"
    },
    FEATURED_CARS: {
        eventName: b2cHomePageEvents.addToWishlistHomePage,
        eventLabel: "Add to wishlist - Featured car",
        removeEventLabel: "Remove from wishlist - Featured car"
    },
    HAND_PICKED_CARS: {
        eventName: b2cPersonalizedCarAEvents.addToWishListInHandPicked,
        addEventLabel: "Add to wishlist - hand_pick",
        removeEventLabel: "Remove from wishlist - hand_pick"
    },
    AI_PICKED_CARS: {
        eventName: carForYouPageEvents.aiAddToWishlist,
        addEventLabel: "Add to wishlist - ai_search",
        removeEventLabel: "Remove from wishlist - ai_search"
    }
};

export const DISCOUNT_FILTERS = ["discountedCars", "lighteningDeals"];
