import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

// Actions
import { addCarToCompare, removeCarCompare, setCompareCarsLimitError } from "../compare-widget/actions";

// Component
import SmallerInventoryCarCard from "./component";

const mapStateToProps = ({
    compareCars: {
        selectedCars: selectedCarsToCompare,
        totalCars: totalSelectedCarsToCompare
    },
    config: {
        resolutionInfo: {
            msite: resolutions
        }
    },
    user: {
        isWebview
    }
}) => ({
    selectedCarsToCompare,
    totalSelectedCarsToCompare,
    resolutions,
    isWebview
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    addCarToCompareConnect: addCarToCompare,
    removeCarCompareConnect: removeCarCompare,
    setComparingCarLimitError: setCompareCarsLimitError
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SmallerInventoryCarCard));
