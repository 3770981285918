import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Components

// Styles
import styles from "./styles.css";

// Images
import IconSafety from "./images/safety.svg";

const SalesLeverV2 = ({ lever }) => {
    return (
        <Fragment>
            {lever ? (
                <div className={"salesLever"} styleName={"styles.wrapper"}>
                    <div styleName={"styles.iconWrapper"}>
                        <img
                            src={IconSafety}
                            width="10"
                            height="10"
                            alt="Offer"
                        />
                    </div>
                    {lever.secondaryLabel ? (
                        <div styleName={"styles.slideWrapper"}>
                            <span styleName={"styles.text1"}>{lever.label}</span>
                            <span styleName={"styles.text_2"}>{lever.secondaryLabel}</span>
                        </div>
                    ) : <p styleName={"styles.leverLabel"}>{lever.label}</p>}
                </div>
            ) : null}
        </Fragment>
    );
};

SalesLeverV2.propTypes = {
    lever: PropTypes.object
};

export default SalesLeverV2;
