/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { Helmet } from "react-helmet-async";

// Styles
import styles from "./styles.css";

// Images
import PriceOffIcon from "../../shared/images/price-off-car-card.svg";
import { NoCarImageFoundPath } from "../no-car-image-found";
import IconHeart from "../../shared/icon-heart/component";
import IconPrimeTag from "../../shared/images/icons/prime-car-tag-new.svg";
import IconSimilar from "../../shared/images/icons/similar-icon-blue.svg";
import IconLiteTag from "../../shared/images/icons/lite-car-tag.svg";
import IconGreenTag from "../../shared/images/icons/green-car-tag.svg";
import IconPrivateSellerProTag from "../../shared/images/icons/private-seller-pro-tag.svg";
import IconPrivateSellerTag from "../../shared/images/icons/private-seller-tag.svg";
import IconLocation from "../../shared/images/icons/location-icon-light-blue.svg";

// Utilities
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import priceFormatter from "../../../utils/helpers/price-formatter";
import getDetailPageUrl from "../../../utils/helpers/get-detail-page-url";
import { checkCarStatus, getShortKmLabel, imageAltTagLabel, getLoanTypeLabelListingV2 } from "../../../utils/helpers/car-properties.ae";
import { getBatteryCapacity } from "../../../utils/helpers/get-battery-capacity";

// Components
import withWishlist from "../with-wishlist";
import withLogin from "../with-login";
import Login from "../login";
import PageLoader from "../../shared/page-loader";
import CarCardTagComponent from "../car-card-tag";
const Checkbox = loadable(() => import("../../shared/checkbox"));
const AdditionalChargesCard = loadable(() => import("../../shared/additional-charges-card"));
const BetaInfoListingPopup = loadable(() => import("../beta-info-listing-popup"), { fallback: <PageLoader /> });

// Hooks
import useToggle from "../../../hooks/use-toggle";

// Constants
import { DISCOUNT_FILTERS, SOURCE, SOURCE_EVENT_DATA } from "./constant";
import { NUMBER, LOGIN_TITLE, CAR_STATUS, ASSORTMENT_CATEGORY_LABEL, INVENTORY_TYPE_LABEL } from "../../../constants/app-constants";
import { SOURCES } from "../login/constants";
import { ROUTE_NAME } from "../../../constants/routes-constants";

// Tracking
import cleverTap from "../../../tracking/clevertap";
import { trackPlatformCustomEventsAEV2 } from "../../../tracking";
import getSimilarCarsUrl from "../../../utils/helpers/get-similar-cars-url";
import { trackWebViewEvents } from "../../../utils/webview-tracking";
import { APP_NAVIGATIONS, WEBVIEW_EVENTS } from "../../../constants";
import yieldToMainThread from "../../../utils/helpers/yield-to-main-thread";
import SalesLeverV2 from "../sales-lever-v2/index";
import LazyImage from "../../shared/lazy-image";
import { b2cListingPageEvents } from "../../../tracking/msite/b2c-listing-page-events";
import { b2cAllPageEvents } from "../../../tracking/msite/b2c-all-page-events";
import { b2cSimilarCarPageEvents } from "../../../tracking/msite/b2c-similar-car-page-events";

const carCategory = {
    [ASSORTMENT_CATEGORY_LABEL.PRIME]: { img: <img width="63" height="16" src={IconPrimeTag} />, event: b2cAllPageEvents.primeClick, borderStyle: "styles.lineBorderSepratorPrime"},
    [ASSORTMENT_CATEGORY_LABEL.LITE]: { img: <img width="53" height="16" src={IconLiteTag} loading="lazy" />, event: b2cAllPageEvents.liteClick, borderStyle: "styles.lineBorderSepratorLite" },
    [ASSORTMENT_CATEGORY_LABEL.GREEN]: { img: <img width="64" height="16" src={IconGreenTag} loading="lazy" />, event: b2cAllPageEvents.greenClick, borderStyle: "styles.lineBorderSepratorGreen" },
    [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER]: { img: <img width="76" height="16" src={IconPrivateSellerTag} loading="lazy" />, event: b2cAllPageEvents.privateSellerClick, borderStyle: "styles.lineBorderSepratorPrivateSeller" },
    [ASSORTMENT_CATEGORY_LABEL.PRIVATE_SELLER_PRO]: { img: <img width="96" height="16" src={IconPrivateSellerProTag} loading="lazy" />, event: b2cAllPageEvents.privateSellerProClick, borderStyle: "styles.lineBorderSepratorPrivateSellerPro"  }
};

const updatedCarSources = [SOURCE?.LISTING, SOURCE.SIMILAR_CARS, SOURCE.WISHLIST, SOURCE.HUB_TEST_DRIVE, SOURCE.HAND_PICKED_CARS];

const SmallerInventoryCarCard = ({
    year,
    appointmentId: carId,
    make,
    model,
    price,
    odometerReading,
    mainImage,
    config = {},
    cardRef,
    history,
    onClickCallback = () => { },
    threeSixtyViewUrl,
    variant,
    isWishlistedCar = () => { },
    onClickAddToWishList,
    canFavourite = true,
    city,
    source,
    additionalCharges = {},
    chargesToastVisibility,
    isLoggedIn,
    showLogin,
    setShowLogin,
    secureToken,
    shouldRedirect = true,
    // isShowViewSimilarIcon = false,
    isActive,
    emiDetails,
    booked,
    specs,
    floodFree,
    salesLever,
    discounted,
    discountAmount = NUMBER.ZERO,
    isComparingActive,
    addCarToCompareConnect,
    removeCarCompareConnect,
    setComparingCarLimitError,
    selectedCarsToCompare,
    totalSelectedCarsToCompare,
    optionsType,
    carCardTag,
    currentCarRef,
    isHotOffer,
    routeName = "",
    resolutions,
    lazyLoadImage = false,
    preload = false,
    listingActive,
    transmissionType,
    fuelType,
    // targetPrice,
    assortmentCategory,
    parentHubLocation,
    batteryCapacity,
    crawable = true,
    isWebview,
    inventoryType,
    categoryComparisons = {},
    hotOfferDetails
}) => {
    const [checkValue, setCheckValue] = useState(false);
    const [showBetaPopup, setShowBetaPopup] = useToggle(false);
    const [showChargesToast, setShowChargesToast] = useState(false);
    const isCarSold = checkCarStatus({ listingActive }, CAR_STATUS.SOLD);
    const isCarReserved = checkCarStatus({ booked, listingActive }, CAR_STATUS.RESERVED);
    const carName = `${make} ${model}`;
    const carNameWithYear = `${year} ${carName}`;
    const isView360Enabled = !!threeSixtyViewUrl;
    const { relativeURL, absoluteURL } = getDetailPageUrl({ year, carName, carId, city, country: "ae" });
    const hasImage = mainImage && mainImage.path;
    const fastlyUrlPayload = {
        basePath: config?.imageHost,
        path: mainImage?.path,
        type: isView360Enabled ? imageTypes.gsCarCard360 : imageTypes.gsCarCard,
        width: (routeName === ROUTE_NAME.LISTING || routeName === ROUTE_NAME.SIMILAR_CARS) ? resolutions.listing : "",
        quality: (routeName === ROUTE_NAME.LISTING || routeName === ROUTE_NAME.SIMILAR_CARS) ? NUMBER.TEN : "",
        queryParams: mainImage?.params
    };
    const imageUrl = hasImage
        ? fastlyUrl(fastlyUrlPayload)
        : NoCarImageFoundPath;
    const imageTagLabel = imageAltTagLabel({ make, model, variant, year, transmissionType, odometerReading, fuelType });
    const additionalChargesForCar = additionalCharges[carId] || [];
    const showWishlistSources = [
        SOURCE.LISTING,
        SOURCE.SIMILAR_CARS,
        SOURCE.RECENT_CARS,
        SOURCE.FEATURED_CARS,
        SOURCE.WISHLIST,
        SOURCE.HAND_PICKED_CARS,
        SOURCE.AI_PICKED_CARS
    ];
    const showWishlist = showWishlistSources.indexOf(source) !== -NUMBER.ONE;
    const { tenure = "", downPaymentPercentage, financeEligibility } = emiDetails || {};
    const isElectricCar = assortmentCategory === ASSORTMENT_CATEGORY_LABEL.GREEN;

    const isShowViewSimilarIcon = () => {
        return checkCarStatus({ booked, listingActive }, CAR_STATUS.RESERVED);
    };

    const preventPropagation = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const trackGaEvents = () => {
        const isCarWishListed = isWishlistedCar(carId);
        const { eventName, addEventLabel, removeEventLabel } = SOURCE_EVENT_DATA[source] || {};
        if (isCarWishListed) {
            trackPlatformCustomEventsAEV2(b2cListingPageEvents.removeFromWishlist, {
                eventLabel: removeEventLabel || carId
            });
        } else {
            trackPlatformCustomEventsAEV2(eventName || b2cListingPageEvents.addToWishlistListingPage, {
                eventLabel: addEventLabel || carId
            });
        }
    };

    const onClickWishlist = (e) => {
        preventPropagation(e);
        if (isCarSold) {
            return;
        }
        if (isLoggedIn) {
            onClickAddToWishList(carId, secureToken, {
                callback: (shortListed) => {
                    if (shortListed) {
                        cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.WISHLISTED_CAR, {
                            content: {
                                appointmentId: carId,
                                make,
                                model,
                                year,
                                mainImage,
                                price,
                                hotOfferDetails,
                                isHotOffer
                            },
                            config
                        });
                    }
                    window.setTimeout(() => {
                        trackGaEvents();
                    }, 0);
                }
            });
        } else {
            setShowLogin(true);
        }
        trackPlatformCustomEventsAEV2(b2cListingPageEvents.listingCarCardWishlistClick, {
            eventLabel: carId
        });
    };

    const checkIfSelectedToCompare = () => {
        return !!selectedCarsToCompare.filter(({ appointmentId }) => appointmentId === carId).length;
    };

    const handleCompareCars = (e) => {
        preventPropagation(e);
        if (!checkIfSelectedToCompare()) {
            const payloadCarToCompare = {
                appointmentId: carId,
                mainImage,
                make,
                model,
                variant,
                year
            };
            if (totalSelectedCarsToCompare >= NUMBER.THREE) {
                setComparingCarLimitError(true);
                window.setTimeout(() => {
                    setComparingCarLimitError(false);
                }, NUMBER.SIX_THOUSAND);
                setCheckValue(false);
                trackPlatformCustomEventsAEV2(b2cAllPageEvents.b2cCompareCarError3cars);
            } else {
                setCheckValue(true);
                window.setTimeout(() => {
                    addCarToCompareConnect({ carDetails: payloadCarToCompare, config });
                }, 0);
                trackPlatformCustomEventsAEV2(b2cAllPageEvents.b2cAddcarCompare, {
                    eventLabel: source
                });
            }
        } else {
            setCheckValue(false);
            window.setTimeout(() => {
                removeCarCompareConnect(carId);
                trackPlatformCustomEventsAEV2(b2cAllPageEvents.b2cRemovecarCompare, {
                    eventLabel: source
                });
            }, 0);
        }
    };
    const onCardClickHandler = (e) => {
        preventPropagation(e);
        if (!isCarSold && shouldRedirect) {
            if (isWebview) {
                trackWebViewEvents(WEBVIEW_EVENTS.REDIRECT, { type: APP_NAVIGATIONS.carDetails, appointmentId: carId, inventoryType });
            } else {
                window.setTimeout(() => {
                    history.push(`${relativeURL.slice(NUMBER.ZERO, -1)}?from=listingPage`);
                }, 0);
            }
            window.setTimeout(() => {
                onClickCallback();
            }, 0);
        } else {
            onClickCallback(e);
        }
    };

    const handleViewSimilarIconClick = (e) => {
        preventPropagation(e);
        const similarCarUrl = getSimilarCarsUrl({ year, appointmentId: carId, make, model }, source);
        window.setTimeout(() => {
            history.push(similarCarUrl);
        }, 0);
        trackPlatformCustomEventsAEV2(b2cSimilarCarPageEvents.viewSimilarCarCard, { eventLabel: source });
    };

    const onCloseChargesToast = (e) => {
        preventPropagation(e);
        setShowChargesToast(false);
    };

    const hideLogin = () => {
        setShowLogin(false);
    };

    const onWishlistLoginSuccess = ({ token }) => {
        hideLogin();
        onClickAddToWishList(carId, token, {
            callback: (shortListed) => {
                if (shortListed) {
                    cleverTap.updateProfileCarDetails(cleverTap.profileUpdateTypes.WISHLISTED_CAR, {
                        content: {
                            appointmentId: carId, make,
                            model, year,
                            mainImage, price, hotOfferDetails, isHotOffer
                        },
                        config
                    });
                }
            }
        });
        trackGaEvents();
    };

    const handleBetaTagClick = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowBetaPopup(true);
        await yieldToMainThread();
        trackPlatformCustomEventsAEV2(carCategory[assortmentCategory]?.event, { eventLabel: source });
    };

    const getOffText = () => {
        if (inventoryType === INVENTORY_TYPE_LABEL.C2C) {
            return "DROP";
        } else {
            return "OFF";
        }
    };

    const getCardFooterLeftElement = () => {

        if (isComparingActive) {
            return  (
                <div styleName={"styles.compareCard"} onClick={handleCompareCars}>
                    <Checkbox checkedStatus={checkIfSelectedToCompare()} />
                    <p styleName={"styles.compareText"}>{!checkValue ? "Add to compare" : "Added to compare"}</p>
                </div>
            );
        }

        if (isShowViewSimilarIcon()) {
            return  (
                <div styleName={"styles.similarCarWrap"} onClick={handleViewSimilarIconClick}>
                    <img src={IconSimilar} alt="" width="16" height="16" />
                    <p styleName={`styles.viewSimilarCta`}>&nbsp; View Similar Cars</p>
                </div>
            );
        }

        if (parentHubLocation?.locationName && updatedCarSources.includes(source)) {
            return  (
                <div styleName="styles.locationWrapper">
                    <img src={IconLocation} height="10" width="10" />
                    <p>{parentHubLocation?.locationName}</p>
                </div>
            );
        }

        return <React.Fragment />;
    };

    return (
        <Login isVisible={showLogin} onClose={hideLogin} onSuccess={onWishlistLoginSuccess} loginSource={LOGIN_TITLE.ADD_TO_WISHLIST} loginFor={SOURCES.ADD_TO_WISHLIST}>
            {/** added helmet for performance improvement via homepage */}
            {preload && (
                <Helmet>
                    <link rel="preload" href={imageUrl} as={"image"} type="image/jpg" fetchPriority="high" />
                </Helmet>
            )}
            <div>
                <div styleName={"styles.carCardWrapper"} ref={currentCarRef}>
                    <a
                        {...(crawable && !isCarSold && { href: absoluteURL })} style={{ minHeight: "128px" }}
                        styleName={`styles.carCard ${isActive ? "styles.cardActive" : ""}`}
                        ref={cardRef}
                        onClick={onCardClickHandler}
                    >
                        <div styleName={"styles.carCardHeader"}>
                            <h3 styleName={"styles.carNameHeading"} dangerouslySetInnerHTML={{ __html: carNameWithYear }} />
                            {canFavourite && showWishlist && (
                                <div styleName={"styles.heartWrap"}>
                                    <IconHeart
                                        active={isWishlistedCar(carId)}
                                        onClick={onClickWishlist}
                                        source="carCardV2"
                                    />
                                </div>
                            )}
                        </div>
                        <div styleName={"styles.flexRowWrapper"}>
                            <div className={"align-self-center carImage"} styleName={`styles.carImage ${isCarReserved && !isShowViewSimilarIcon() ? "styles.reservedCarImage" : ""}`}>
                                {/* {isCarSold && (
                                    <p styleName="styles.carSoldIconWrapper">SOLD</p>
                                )} */}

                                {!lazyLoadImage ?
                                    <img src={imageUrl} width="114" height="64"
                                        styleName={`styles.mainImage ${isCarSold ? "styles.carSoldOpacity" : ""}`}
                                        alt={imageTagLabel}
                                    /> :
                                    <LazyImage
                                        styleName={`styles.mainImage ${isCarSold ? "styles.carSoldOpacity" : ""}`}
                                        alt={imageTagLabel}
                                        src={imageUrl}
                                        width="114"
                                        height="64"
                                    />
                                }
                                {carCardTag &&  <CarCardTagComponent carCardTag={carCardTag} />}
                            </div>
                            <div styleName={`styles.outer ${isCarSold ? "styles.carSoldOpacity" : ""}`}>
                                <div styleName={"styles.carNameWrap"}>
                                    <p styleName={`styles.modelName`}>
                                        {optionsType && <span>{optionsType}</span>}
                                        {variant && <span>{variant}</span>}
                                    </p>
                                </div>
                                <div styleName={"styles.cardMeta"}>
                                    {!isCarSold ?
                                        <Fragment>
                                            <div styleName={"styles.emiValueWrap"}>
                                                <div styleName={"styles.carEmiPrice"}>
                                                    <span styleName={"styles.currencyText"}>AED</span>
                                                    <strong>{priceFormatter(price, true, "AE")}</strong>
                                                    {/* {discounted && <span styleName={"styles.fixedPriceText"}>{priceFormatter(targetPrice, true, "AE")}</span>} */}
                                                    {discounted && <span styleName={"styles.offText"}>{discountAmount} {getOffText()}</span>}
                                                </div>
                                            </div>
                                            <div styleName={"styles.carEmiDetail"}>
                                                <div styleName={"styles.dFlex"}>
                                                    {emiDetails && emiDetails.emi && (
                                                        <p styleName={"styles.priceText"}>
                                                            <span styleName={"styles.emiText"}>EMI</span>{priceFormatter(emiDetails.emi, true, "AE")}<span>/mo</span>
                                                            <span styleName={"styles.fontLite"}> | {tenure}yrs, {getLoanTypeLabelListingV2(financeEligibility, downPaymentPercentage)}</span>
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </Fragment> :
                                        <p styleName={"styles.carSoldText"}>This car has been sold recently</p>
                                    }
                                    {DISCOUNT_FILTERS.includes(salesLever?.key) && (
                                        <div styleName={"styles.priceOffStrip"}>
                                            <img src={PriceOffIcon} alt="price off" width={12} height={12} />
                                            {salesLever?.secondaryLabel || salesLever?.label}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div styleName={`styles.carCardFooter ${isCarSold ? "styles.carSoldOpacity" : ""}`}>
                            <div styleName={"styles.kilometerWrapper"}>
                                <p styleName={"styles.kilometerIconWrapper"}>{getShortKmLabel(odometerReading)}</p>
                                {(isElectricCar && batteryCapacity) && <p styleName={"styles.kilometerIconWrapper"}>{getBatteryCapacity(batteryCapacity)} </p>}
                                <p styleName={"styles.kilometerIconWrapper styles.noAfterMark"}>{`${specs === "GCC" ? specs : "NON GCC"}`}</p>
                                {floodFree && <p styleName={"styles.kilometerIconWrapper styles.noAfterMark"}>Flood free</p>}
                            </div>
                            {!DISCOUNT_FILTERS.includes(salesLever?.key) && <SalesLeverV2 lever={salesLever} />}
                        </div>
                        <span styleName={carCategory?.[assortmentCategory]?.borderStyle} />
                        <div styleName={"styles.cardFooter"}>
                            {getCardFooterLeftElement()}
                            {carCategory?.[assortmentCategory]?.img && (
                                <div styleName={"styles.assormentTag"} onClick={(e) => handleBetaTagClick(e)}>
                                    {carCategory?.[assortmentCategory]?.img}
                                </div>
                            )}
                        </div>
                    </a>
                    {chargesToastVisibility && showChargesToast && (
                        <AdditionalChargesCard charges={additionalChargesForCar} onClose={onCloseChargesToast} />
                    )}
                </div>
                {showBetaPopup && (
                    <BetaInfoListingPopup
                        categoryComparisons={categoryComparisons}
                        assortmentCategory={assortmentCategory}
                        onClose={() => setShowBetaPopup(false)}
                    />
                )}
            </div>
        </Login>
    );
};

SmallerInventoryCarCard.propTypes = {
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    make: PropTypes.string,
    model: PropTypes.string,
    price: PropTypes.number,
    odometerReading: PropTypes.number,
    cardRef: PropTypes.object,
    currentCarRef: PropTypes.object,
    city: PropTypes.string,
    variant: PropTypes.string,
    optionsType: PropTypes.string,
    isWishlistedCar: PropTypes.func,
    canFavourite: PropTypes.bool,
    mainImage: PropTypes.object,
    config: PropTypes.object,
    history: PropTypes.object,
    onClickCallback: PropTypes.func,
    threeSixtyViewUrl: PropTypes.bool,
    onClickAddToWishList: PropTypes.func,
    source: PropTypes.string,
    additionalCharges: PropTypes.object,
    chargesToastVisibility: PropTypes.bool,
    appointmentId: PropTypes.string,
    isLoggedIn: PropTypes.bool,
    showLogin: PropTypes.bool,
    setShowLogin: PropTypes.func,
    secureToken: PropTypes.string,
    shouldRedirect: PropTypes.bool,
    isActive: PropTypes.bool,
    emiDetails: PropTypes.object,
    booked: PropTypes.bool,
    specs: PropTypes.string,
    isShowViewSimilarIcon: PropTypes.bool,
    salesLever: PropTypes.object,
    discounted: PropTypes.bool,
    targetPrice: PropTypes.number,
    isComparingActive: PropTypes.bool,
    addCarToCompareConnect: PropTypes.func,
    removeCarCompareConnect: PropTypes.func,
    selectedCarsToCompare: PropTypes.array,
    totalSelectedCarsToCompare: PropTypes.number,
    setComparingCarLimitError: PropTypes.func,
    resolutions: PropTypes.object,
    routeName: PropTypes.string,
    lazyLoadImage: PropTypes.bool,
    preload: PropTypes.bool,
    listingActive: PropTypes.bool,
    discountAmount: PropTypes.number,
    transmissionType: PropTypes.string,
    fuelType: PropTypes.string,
    assortmentCategory: PropTypes.string,
    parentHubLocation: PropTypes.object,
    batteryCapacity: PropTypes.number,
    crawable: PropTypes.bool,
    isSimilarPage: PropTypes.bool,
    isWebview: PropTypes.bool,
    carCardTag: PropTypes.string,
    floodFree: PropTypes.bool,
    inventoryType: PropTypes.string,
    categoryComparisons: PropTypes.object,
    isHotOffer: PropTypes.bool,
    hotOfferDetails: PropTypes.object
};

export default withLogin(withWishlist(SmallerInventoryCarCard));
