
export const b2cPersonalizedCarAEvents = {
    "handPickedCarPageVisit": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PersonalisedCars_Page",
        "eventAction": "PersonalisedCars_page_visited"
    },
    "handPickedCarClick": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PersonalisedCars_Page",
        "eventAction": "personalised_car_click"
    },
    "addToWishListInHandPicked": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PersonalisedCars_Page",
        "eventAction": "add_to_wishlist"
    },
    "handPickSeeAll": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PersonalisedCars_Page",
        "eventAction": "personalised_cars_see_all"
    },
    "handPickBack": {
        "event": "custom_event",
        "eventCategory": "Buy_used_cars_B2C_PersonalisedCars_Page",
        "eventAction": "personalised_cars_back"
    }
};
